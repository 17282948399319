import { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications';
import Header from '../../components/header'
import Footer from '../../components/footer'
import api from './../../services/api'

export default function Pedidos(props) {
  const [listaPedidos, setListaPedidos] = useState([])

  useEffect(() => {
    api.get(`Rastreio/GetTodosPedidosUsuario`).then(response => {
      setListaPedidos(response.data)

    }).catch(err => {
      console.log("ERRO: ", err)
      NotificationManager.error('Não foi possível carregar seus pedidos');
    })
  }, [setListaPedidos]);

  const abrirDetalhes = (status, guid) => {
    if (status === 1)
      props.history.push(`/checkout/${guid}`)
    else
      props.history.push(`/detalhes/${guid}`)
  }

  const BaixarDocumentoFiscal = (guid) => {
    api.get(`DocumentoFiscal/BaixarDocumentoFiscal/${guid}`).then(response => {
      console.log(response)
    }).catch(err => {
      console.log("ERRO: ", err)
      NotificationManager.error('Não foi possível baixar documento');
    })
    console.log(guid)
  }

  // function dataAtualFormatada() {
  //   var data = new Date();
  //   data.setDate(data.getDate() + 5);

  //   var dia = data.getDate().toString(),
  //     diaF = (dia.length === 1) ? '0' + dia : dia,
  //     mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
  //     mesF = (mes.length === 1) ? '0' + mes : mes,
  //     anoF = data.getFullYear();
  //   return diaF + "/" + mesF + "/" + anoF;
  // }

  return (
    <div style={{ backgroundColor: '#f1f4ff' }}>
      <Header centralizar xsmall />
      <section>
        <div className="container mt-150 mb-150">
          <div className="row">
            <div className="col-md-12 col-lg-8 offset-lg-2">
              <div>
                <h3 className="m-0">Pedidos</h3>
                <p>{('0' + listaPedidos.length).slice(-4)} Pedidos</p>
              </div>
              <div className="pedidos" >
                <div className="pedidos-head">
                  <span>Status do pedido</span>
                  <select className="input input-sm">
                    <option value="">Todos</option>
                    <option value="entregue">Entregue</option>
                    <option value="acaminho">A Caminho</option>
                    <option value="pendentePagamento">Pendente de Pagamento</option>
                    <option value="criada">Criada</option>
                  </select>
                </div>
                <div className="table-div">
                  <div className="table-box-titulo">
                    {/* <div>Pedido</div> */}
                    <div>Data Pagamento</div>
                    <div>Previsão de Entrega</div>
                    <div>Preço</div>
                    <div>Status</div>
                    <div>Rastreio</div>
                  </div>
                  {
                    listaPedidos.map((x, y) => (
                      <div key={y} className="table-box">
                        {/* <div>#{("0000" + (y + 1)).slice(-4)}</div> */}
                        <div className='cursor' onClick={() => abrirDetalhes(x.codigoStatus, x.guid)}>{x.strDataPagamento || "--"}</div>
                        <div className='cursor' onClick={() => abrirDetalhes(x.codigoStatus, x.guid)}>{x.strPrevisaoEntrega || "--"}</div>
                        {/* <div>{x.prazo} dias corridos</div> */}
                        <div className='cursor' onClick={() => abrirDetalhes(x.codigoStatus, x.guid)}>R$ {x.valor}</div>
                        <div>
                          {x.codigoStatus === 1 && <span className="badge bg-secondary">{x.status}</span>}
                          {x.codigoStatus === 2 && <a href={x.linkPagamento} onClick={() => Event.preventDefault()} className="badge bg-warning">{x.status}</a>}
                          {x.codigoStatus === 3 && <span className="badge bg-info">{x.status}</span>}
                          {x.codigoStatus === 3 && <button className="btn btn-link" onClick={() => BaixarDocumentoFiscal(x.guid)}>BAIXAR</button>}
                        </div>
                        <div className='cursor' onClick={() => abrirDetalhes(x.codigoStatus, x.guid)}>{x.rastreio}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
import { Form } from '@unform/web'
import { useRef, useState } from 'react'
import Input from '../form/input'
import api from '../../services/api'
import { ChaveRecaptcha } from '../../services/utils'
import ReCAPTCHA from "react-google-recaptcha"


export default function BoxEntrarEmContato() {
  const [counting, setCounting] = useState(false)
  const recaptchaRef = useRef()
  const formRef = useRef(null)
  const btnEnviar = useRef(null)

  const Enviar = (data) => {
    setCounting(true)
    api.post('contato/EnviarMensagemDeContato', data).then(r => {
      console.log(formRef)
      formRef.current.reset()
      btnEnviar.current.innerHTML = 'ENVIADO!'
      btnEnviar.current.disabled = true
    }).catch(err => {
      console.log('ERRO: ', err)
    });
  }

  const onSubmitWithReCAPTCHA = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    data.RecaptchaResponse = token.toString();
    Enviar(data);
    // apply to form data
  }

  return <>
    <div className="box-white">
      <h4 className="mb-20"><small>Entre em contato</small></h4>
      <Form ref={formRef} onSubmit={onSubmitWithReCAPTCHA}>
        <div className="row">
          <div className="col-sm-6"><Input type="text" name="Nome" title="Nome" /></div>
          <div className="col-sm-6"><Input type="text" name="Celular" title="Celular" /></div>
          <div className="col-sm-12"><Input type="text" name="Email" title="E-mail" /></div>
          <div className="col-sm-12"><Input type="text" name="Mensagem" title="Mensagem" /></div>
          <div className="col-sm-12 d-grid">
            <button id='btnEnviar' ref={btnEnviar} type="submit" className="btn btn-primary">{counting ? <i className="fas fa-spinner fa-spin"></i> : 'Enviar'}</button>
          </div>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={ChaveRecaptcha}
        // onChange={Enviar}
        />
      </Form>

    </div>
  </>
}
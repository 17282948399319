// import React, { useState } from "react"
import { ContextApp } from './services/contextApp'
import { NotificationContainer } from 'react-notifications';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-notifications/lib/notifications.css';
import 'animate.css';
import './assets/styles/css/fontawesome/all.min.css'
import "./assets/styles/css/style.css";

import { BrowserRouter as Router } from "react-router-dom";
import MainRoutes from './Routes'

function App() {
  return (
    <ContextApp>
      <Router>
        <MainRoutes />
      </Router>
      <NotificationContainer />
    </ContextApp>
  );
}

export default App;

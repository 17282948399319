import IsAuthenticated from '../../services/auth'
import { FormatarValor } from '../../services/utils'
import { PopoverPrazos } from '../../components/utils'
// import api from './../../services/api'

export default function PrecosPorServicoCotado(attr) {
  const { codigoServico, deadline, price } = attr
  const transportar = () => {
    if (IsAuthenticated())
      attr.transportarCotacao(codigoServico)
    else {
      document.getElementsByClassName("btnModalLogin")[0].click()
      attr.salvarNoLocalStorage(codigoServico)
    }
  }

  return <div>
    <div className="result-quote">
      <div className="row">
        <div className="col-sm-4">
          <div className="time-quote"><span>Prazo</span> <PopoverPrazos /><br /><b className="text-azulpontual">até {deadline} dias corridos</b></div>
        </div>
        <div className="col-sm-4">
          <div className="value-quote">R$ <FormatarValor valor={price} tipo="BRL" /></div>
        </div>
        <div className="col-sm-4 actions-quote">
          <button className="btn btn-primary btn-wide" onClick={transportar}>Transportar</button>
          <button className="btn btn-primary btn-wide hide btnModalLogin" hidden data-bs-toggle="modal" data-bs-target="#modalLogin"></button>
        </div>
        <div className="col-sm-8 text-attention">
          <p style={{fontSize: "11.5px", paddingTop: '12px' }}>
            <span style={{ color: 'red', fontWeight: 'bold' }}>Atenção:</span> Os valores informados acima poderão ser modificados após a conferência física do peso e dimensões no ato do embarque, gerando cobranças adicionais e acréscimo de taxa de embalagem , caso o(s) volume(s) não esteja(m) adequadamente embalado(s).
          </p>
        </div>
      </div>
    </div>
    {/* <hr></hr>
      <h5 className="title-quote">{description}</h5>
      <div className="description-quote">
        <div className="time-quote"><span>Prazo</span><br /><b>{deadline} dias corridos</b></div>
        <div className="value-quote">R$ <FormatarValor valor={price} tipo="BRL" /></div>
      </div>
      <div className="actions-quote">
        <button className="btn btn-primary btn-wide" onClick={transportar}>Transportar</button>
        <button className="btn btn-primary btn-wide hide btnModalLogin" hidden data-bs-toggle="modal" data-bs-target="#modalLogin"></button>
      </div> */}

  </div >
}
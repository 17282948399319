import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom'
import api from '../../services/api'
import Header from '../../components/header'
import Footer from '../../components/footer'
import storage from '../../services/storage'
import { DesativarBotaoCarregando } from '../../services/utils'
import { useEffect } from 'react';

export default function NovaSenha(props) {
  const { identificador, securityStamp } = props.match.params

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const alterarSenha = () => {

    let confirmarSenha = document.getElementById("confirmarSenha").value
    let novaSenha = document.getElementById("novaSenha").value
    let mensagemElm = document.getElementById("mensagem")

    if (novaSenha === "") {
      mensagemElm.innerHTML = "Informe uma senha"
      return
    }

    if (confirmarSenha !== novaSenha) {
      mensagemElm.innerHTML = "As senhas não são iguais"
      return
    }

    DesativarBotaoCarregando("btnConfirmar", "...")

    api.post(`User/AlterarSenha`, { identificador, securityStamp, novaSenha }).then(response => {
      console.log(response)
      if (response.data.isValid) {
        storage.setToken(response.data.objRetorno.token)
        window.location.href = '/'
      } else {
        mensagemElm.innerHTML = response.data.mensagem
        DesativarBotaoCarregando("btnConfirmar", "Tentar novamente")
      }
    }).catch(err => {
      console.log("ERRO: ", err)
      NotificationManager.error('Não foi possível concluir a solicitação');
      DesativarBotaoCarregando("btnConfirmar", "Tentar novamente")
    })
  }

  return (
    <div style={{ backgroundColor: '#f1f4ff' }}>
      <Header titulo="" centralizar none />
      {/* <h2 className="text-center pt-150">Recuperação de Senha</h2> */}
      <section>
        <div className="container pt-150 mb-150">
          <div className="row">
            <div className="col-md-4 offset-md-4">
              <div className="box-white">
                <h4 className="text-center">Criar Senha</h4>
                <div className="text-vermelhopontual text-center pb-20" id="mensagem">&nbsp;</div>
                <div id="formSolicitacao">
                  <form >
                    <input type="password" className="input" id="novaSenha" placeholder="Nova Senha" />
                    <input type="password" className="input" id="confirmarSenha" placeholder="Confirmar Senha" />
                    <button type="submit" className="btn btn-primary btn-block" id="btnConfirmar" onClick={() => alterarSenha()}>Confirmar</button>
                  </form>
                </div>
              </div>
              <div className="text-center mt-30">
                <Link to="/">Voltar para a Home</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
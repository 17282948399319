// import FormMyData from "./../formMyData"
import { Link } from 'react-router-dom'
import { useRef, useEffect, useState } from 'react'
import { Form } from '@unform/web'
import api from '../../services/api'
import { FormatarValor } from '../../services/utils'
import { PopoverPrazos } from '../../components/utils'
import Input from '../form/input'
import SelectInput from '../form/selectInput'
import InputCheckbox from '../form/inputCheckbox'
import CpfCnpjInput from '../form/cpfCnpjInput'
import Textarea from '../form/textarea'
import modeloDeclaracaoPDF from '../../assets/media/Formulario_Declaracao_de_Conteudo_A4.docx'

export default function CheckoutFormulario(props, ref) {
  const { step, setStepForm, guid, dadosCheckout } = props
  const atualizarRemetente = (data) => {
    dadosCheckout.remetenteNome = data.RemetenteNome
    dadosCheckout.remetenteBairro = data.RemetenteBairro
    dadosCheckout.remetenteComplemento = data.RemetenteComplemento
    dadosCheckout.remetenteCpfcnpj = data.RemetenteCPFCNPJ
    dadosCheckout.remetenteInscricaoEstadual = data.RemetenteInscricaoEstadual
    dadosCheckout.remetenteBolIsentoIE = data.RemetenteBolIsentoIE
    dadosCheckout.remetenteLogradouro = data.RemetenteLogradouro
    dadosCheckout.remetenteNumero = data.RemetenteNumero
    // dadosCheckout.remetenteMunicipio = data.RemetenteMunicipio
    // dadosCheckout.remetenteUF = data.RemetenteUF
    // console.log("Dados REM", dadosCheckout, data)
  }
  const atualizarDestinatario = (data) => {
    dadosCheckout.destinatarioNome = data.DestinatarioNome
    dadosCheckout.destinatarioBairro = data.DestinatarioBairro
    dadosCheckout.destinatarioComplemento = data.DestinatarioComplemento
    dadosCheckout.destinatarioCpfcnpj = data.DestinatarioCPFCNPJ
    dadosCheckout.destinatarioLogradouro = data.DestinatarioLogradouro
    dadosCheckout.destinatarioNumero = data.DestinatarioNumero
    // dadosCheckout.remetenteMunicipio = data.RemetenteMunicipio
    // dadosCheckout.remetenteUF = data.RemetenteUF
    // console.log("Dados REM", dadosCheckout, data)
  }

  return <div>
    <div className="mb-60">
      <div className="row">
        <div id='informacoes-carga' className="col-md-12">
          <h4 className="mb-20">Informações da Carga</h4>
          <p>
            <span>De <b>{dadosCheckout.remetenteMunicipio} - {dadosCheckout.remetenteUF}</b> para <b>{dadosCheckout.destinatarioMunicipio} - {dadosCheckout.destinatarioUF}</b></span><br />
            {!dadosCheckout.cargas || <span>Contendo as seguintes dimensões <b> L {dadosCheckout.cargas[0].largura}cm  x C {dadosCheckout.cargas[0].comprimento}cm x A {dadosCheckout.cargas[0].altura}cm</b>, <br />com o peso de <b>{dadosCheckout.cargas[0].peso}kg</b> e valor declarado de <b>R$ <FormatarValor valor={dadosCheckout.cargas[0].valorDeclarado} tipo="BRL" /></b>.<br /></span>}
            <span>Com o prazo de até <b>{dadosCheckout.prazo} dias corridos <PopoverPrazos /></b> no valor de <b>R$ <FormatarValor valor={dadosCheckout.valor} tipo="BRL" /></b></span>.
            {/* Serviço solicitado <b>{dadosCheckout.servico}</b> // Mencionado antes do prazo */}
          </p>
        </div>
      </div>
      {/* <hr className="mb-30" /> */}
      {/* {
        step >= 2 ? <MeusDados /> : null
      } */}
      <hr className="mb-30" />
      <div className="row">
        {
          step >= 3 ? <Remetente dadosCheckout={dadosCheckout} setStepForm={setStepForm} /> : null
        }
        {
          step >= 4 ? <Destinatario dadosCheckout={dadosCheckout} setStepForm={setStepForm} /> : null
        }

      </div>
    </div>
    {
      step === 2 ? <FormRemetente setStepForm={setStepForm} guid={guid} dadosCheckout={dadosCheckout} atualizarRemetente={atualizarRemetente} /> : null
    }
    {
      step === 3 ? <FormDestinatario setStepForm={setStepForm} guid={guid} dadosCheckout={dadosCheckout} atualizarDestinatario={atualizarDestinatario} /> : null
    }
    {
      step === 4 ? <FormPagamento setStepForm={setStepForm} guid={guid} dadosCheckout={dadosCheckout} /> : null
    }
  </div >
}

export function Remetente(props) {
  const { dadosCheckout, setStepForm, bolBasico } = props

  return (<>
    <div className="col-md-5">
      {
        bolBasico || <h4 className="mb-20 cursor" onClick={() => setStepForm(2)}>
          Remetente &nbsp;&nbsp;
          <i className="fas fa-pencil-alt text-sm text-danger" style={{ fontSize: '18px' }}></i>
        </h4>
      }
      {
        !bolBasico || <h4 className="mb-20">Remetente</h4>
      }
      <p>
        <b>{dadosCheckout.remetenteNome}</b> <small>{bolBasico || "(" + dadosCheckout.remetenteCpfcnpj + ")"}</small><br />
        {dadosCheckout.remetenteInscricaoEstadual ? <span>IE: {dadosCheckout.remetenteInscricaoEstadual}<br /></span> : null}
        <span>{dadosCheckout.remetenteLogradouro}, n°{dadosCheckout.remetenteNumero} - {dadosCheckout.remetenteBairro}</span><br />
        {dadosCheckout.remetenteComplemento ? <span>{dadosCheckout.remetenteComplemento}<br /></span> : null}
        <span>{dadosCheckout.remetenteMunicipio} - {dadosCheckout.remetenteUF}</span><br />
        <span>{dadosCheckout.cepOrigem}</span><br />
      </p>
    </div>
  </>)
}

export function Destinatario(props) {
  const { dadosCheckout, setStepForm, bolBasico } = props

  return (<>
    <div className="col-md-2">
      <div className="d-none d-md-block"><div className="vl-wrap"><div className="vl" style={{ height: '150px' }}></div></div></div>
    </div>
    <div className="col-md-5">
      {
        bolBasico || <h4 className="mb-20 cursor" onClick={() => setStepForm(3)}>
          Destinatário &nbsp;&nbsp;
          <i className="fas fa-pencil-alt text-sm text-danger" style={{ fontSize: '18px' }}></i>
        </h4>
      }
      {
        !bolBasico || <h4 className="mb-20">Destinatário</h4>
      }

      <p>
        <b>{dadosCheckout.destinatarioNome}</b> <small>{bolBasico || "(" + dadosCheckout.destinatarioCpfcnpj + ")"}</small><br />
        {dadosCheckout.destinatarioInscricaoEstadual ? <span>IE: {dadosCheckout.destinatarioInscricaoEstadual}<br /></span> : null}
        <span>{dadosCheckout.destinatarioLogradouro}, n°{dadosCheckout.destinatarioNumero} - {dadosCheckout.destinatarioBairro}</span><br />
        {dadosCheckout.destinatarioComplemento ? <span>{dadosCheckout.destinatarioComplemento}<br /></span> : null}
        <span>{dadosCheckout.destinatarioMunicipio} - {dadosCheckout.destinatarioUF}</span><br />
        <span>{dadosCheckout.cepDestino}</span><br />
      </p>
    </div>
  </>)
}

function FormRemetente(props) {
  const formRef = useRef(null)
  const { setStepForm, guid, dadosCheckout, atualizarRemetente } = props

  useEffect(() => {
    // console.log("AQUI", dadosCheckout)
    let origem = dadosCheckout.remetenteMunicipio ? dadosCheckout.remetenteMunicipio + '-' + dadosCheckout.remetenteUF + ' (' + dadosCheckout.cepOrigem + ')' : ''

    formRef.current.setData({
      CepOrigem: origem, //dadosCheckout.cepOrigem || '',
      RemetenteBairro: dadosCheckout.remetenteBairro || '',
      RemetenteCPFCNPJ: dadosCheckout.remetenteCpfcnpj || '',
      RemetenteInscricaoEstadual: dadosCheckout.remetenteInscricaoEstadual || '',
      RemetenteBolIsentoIE: dadosCheckout.remetenteBolIsentoIE || false,
      RemetenteComplemento: dadosCheckout.remetenteComplemento || '',
      RemetenteLogradouro: dadosCheckout.remetenteLogradouro || '',
      RemetenteNome: dadosCheckout.remetenteNome || '',
      RemetenteNumero: dadosCheckout.remetenteNumero || ''
    })

    if (dadosCheckout.remetenteCpfcnpj && dadosCheckout.remetenteCpfcnpj.length === 14) {
      document.getElementById("r_dadosPJ").style.display = 'flex';
      document.getElementsByName("RemetenteInscricaoEstadual")[0].disabled = !!dadosCheckout.remetenteBolIsentoIE
    }

  }, [dadosCheckout])

  const salvarDados = (data) => {
    data.guid = guid
    data.RemetenteCPFCNPJ = data.RemetenteCPFCNPJ.replace(/\D/g, '')

    api.post('cotacao/UpdateRemetente', data).then(r => {
      atualizarRemetente(data)
      setStepForm(3)
    }).catch(err => {
      console.log('ERRO: ', err)
    });
  }

  const verificaSeExibiInformacaoPJ = (e) => {

    const r_dadosPJ = document.getElementById("r_dadosPJ")
    const ie = document.getElementById("remetenteInscricaoEstadual")

    if (e.target.value.replace(/\D/g, '').length === 14) {
      r_dadosPJ.style.display = 'flex';
      ie.required = true;
    }
    else {
      r_dadosPJ.style.display = 'none';
      ie.required = false;
    }
  }

  const insentoDeIncricaoEstadual = (e) => {
    const inputElm = document.getElementsByName("RemetenteInscricaoEstadual")[0]
    inputElm.disabled = !e.target.checked
    inputElm.required = e.target.checked

    if (!e.target.checked)
      inputElm.value = 'ISENTO'
    else
      inputElm.value = ''
  }

  return (<>
    <div className="row">
      <div className="col-md-12 mb-60">
        <h3>Local de coleta</h3>
      </div>
    </div>
    <Form ref={formRef} onSubmit={salvarDados}>
      <div className="row">
        <div className="col-md-5">
          <CpfCnpjInput name="RemetenteCPFCNPJ" type="text" title="CPF / CNPJ" onKeyUp={(e) => verificaSeExibiInformacaoPJ(e)} required />
        </div>
        <div className="col-md-7">
          <Input name="RemetenteNome" title="Nome Remetente" type="text" required />
        </div>
      </div>
      <div className="row" id="r_dadosPJ" style={{ display: 'none' }}>
        <div className="col-md-6">
          <Input name="RemetenteInscricaoEstadual" id="remetenteInscricaoEstadual" type="text" title="Inscrição Estadual" />
        </div>
        <div className="col-md-6">
          <div className="form-check pt-15">
            <InputCheckbox name="RemetenteBolIsentoIE" className="form-check-input" type="checkbox" id="r_isentoIE" onMouseUp={e => insentoDeIncricaoEstadual(e)} />
            <label className="form-check-label" htmlFor="r_isentoIE">
              Isento IE
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Input name="CepOrigem" type="text" title="CEP Origem" disabled />
        </div>
        <div className="col-md-6">
          <Input name="RemetenteLogradouro" type="text" title="Logradouro" required />
        </div>
        <div className="col-md-2">
          <Input name="RemetenteNumero" type="text" title="Número" required />
        </div>
        <div className="col-md-6">
          <Input name="RemetenteComplemento" type="text" title="Complemento (opcional)" />
        </div>
        <div className="col-md-6">
          <Input name="RemetenteBairro" type="text" title="Bairro" required />
        </div>
      </div>
      <div className="text-center">
        {/* <button type="submit" className="btn btn-link " onClick={() => props.setStepForm(1)}>Voltar</button> */}
        <button type="submit" className="btn btn-primary btn-wide">Avançar</button>
      </div>
    </Form>
  </>)
}

function FormDestinatario(props) {
  const formRef = useRef(null)
  const { setStepForm, guid, dadosCheckout, atualizarDestinatario } = props

  useEffect(() => {
    // console.log("AQUI", dadosCheckout)
    let destino = dadosCheckout.destinatarioMunicipio ? dadosCheckout.destinatarioMunicipio + '-' + dadosCheckout.destinatarioUF + ' (' + dadosCheckout.cepDestino + ')' : ''

    formRef.current.setData({
      CepDestino: destino, //dadosCheckout.cepDestino,
      DestinatarioBairro: dadosCheckout.destinatarioBairro,
      DestinatarioCPFCNPJ: dadosCheckout.destinatarioCpfcnpj,
      DestinatarioInscricaoEstadual: dadosCheckout.destinatarioInscricaoEstadual || '',
      DestinatarioBolIsentoIE: dadosCheckout.destinatarioBolIsentoIE || false,
      DestinatarioComplemento: dadosCheckout.destinatarioComplemento,
      DestinatarioLogradouro: dadosCheckout.destinatarioLogradouro,
      DestinatarioNome: dadosCheckout.destinatarioNome,
      DestinatarioNumero: dadosCheckout.destinatarioNumero
    })

    if (dadosCheckout.destinatarioCpfcnpj && dadosCheckout.destinatarioCpfcnpj.length === 14) {
      document.getElementById("d_dadosPJ").style.display = 'flex';
      document.getElementsByName("DestinatarioInscricaoEstadual")[0].disabled = !!dadosCheckout.destinatarioBolIsentoIE
    }
  }, [dadosCheckout])

  const salvarDados = (data) => {
    data.guid = guid
    data.DestinatarioCPFCNPJ = data.DestinatarioCPFCNPJ.replace(/\D/g, '')

    api.post('cotacao/UpdateDestinatario', data).then(r => {
      atualizarDestinatario(data)
      setStepForm(4)
    }).catch(err => {
      console.log('ERRO: ', err)
    });
  }

  const verificaSeExibiInformacaoPJ = (e) => {

    const r_dadosPJ = document.getElementById("d_dadosPJ")
    const ie = document.getElementById("destinatarioInscricaoEstadual")

    if (e.target.value.replace(/\D/g, '').length === 14) {
      r_dadosPJ.style.display = 'flex';
      ie.required = true;
    }
    else {
      r_dadosPJ.style.display = 'none';
      ie.required = false;
    }
  }

  const insentoDeIncricaoEstadual = (e) => {
    const inputElm = document.getElementsByName("DestinatarioInscricaoEstadual")[0]
    inputElm.disabled = !e.target.checked
    inputElm.required = e.target.checked
    if (!e.target.checked)
      inputElm.value = 'ISENTO'
    else
      inputElm.value = ''
  }

  return (<>
    <Form ref={formRef} onSubmit={salvarDados}>
      <div className="row">
        <div className="col-md-12 mb-60">
          <h3>Local de entrega</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <CpfCnpjInput name="DestinatarioCPFCNPJ" type="text" title="CPF / CNPJ" onKeyUp={(e) => verificaSeExibiInformacaoPJ(e)} required />
        </div>
        <div className="col-md-7">
          <Input name="DestinatarioNome" type="text" title="Nome" required />
        </div>
      </div>
      <div className="row" id="d_dadosPJ" style={{ display: 'none' }}>
        <div className="col-md-6">
          <Input name="DestinatarioInscricaoEstadual" id="destinatarioInscricaoEstadual" type="text" title="Inscrição Estadual" />
        </div>
        <div className="col-md-6">
          <div className="form-check pt-15">
            <InputCheckbox name="DestinatarioBolIsentoIE" className="form-check-input" type="checkbox" id="d_isentoIE" onMouseUp={e => insentoDeIncricaoEstadual(e)} />
            <label className="form-check-label" htmlFor="d_isentoIE">
              Isento IE
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Input name="CepDestino" type="text" title="CEP" disabled />
        </div>
        <div className="col-md-6">
          <Input name="DestinatarioLogradouro" type="text" title="Logradouro" required />
        </div>
        <div className="col-md-2">
          <Input name="DestinatarioNumero" type="text" title="Número" required />
        </div>
        <div className="col-md-6">
          <Input name="DestinatarioComplemento" type="text" title="Complemento (opcional)" />
        </div>
        <div className="col-md-6">
          <Input name="DestinatarioBairro" type="text" title="Bairro" required />
        </div>
      </div>
      <div className="text-center">
        <button type="submit" className="btn btn-primary btn-wide">Avançar</button>
        {/* <br />
        <button type="submit" className="btn btn-link " onClick={() => setStepForm(2)}>Voltar</button> */}
      </div>
    </Form>
  </>)
}

function FormPagamento(props) {
  const [ErroTermoObrigatorio, SetErroTermoObrigatorio] = useState("")
  const [ErroTipoCarga, SetErroTipoCarga] = useState("")
  const formRef = useRef(null)
  const { guid, dadosCheckout } = props

  useEffect(() => {
    formRef.current.setData({
      codigoTipoCargaEspecifica: dadosCheckout.cargas[0].codigoTipoCargaEspecifica || 0,
      bolTermosAceito: dadosCheckout.bolTermosAceito || false,
      observacaoCarga: dadosCheckout.cargas[0].observacao || ""
    })
  }, [dadosCheckout])

  const salvarDados = (data) => {
    SetErroTipoCarga("")
    SetErroTermoObrigatorio("")

    data.guid = guid
    formRef.current.setFieldError('codigoTipoCargaEspecifica', 'Deve ser selecionado');
    formRef.current.setFieldError('bolTermosAceito', 'Deve aceitar os nossos termos');

    if (!data.codigoTipoCargaEspecifica || data.codigoTipoCargaEspecifica <= 0)
      SetErroTipoCarga("Selecione o tipo de carga")

    if (!data.bolTermosAceito)
      SetErroTermoObrigatorio("Você concorda com os nossos termos?")

    if (data.codigoTipoCargaEspecifica <= 0 || !data.bolTermosAceito)
      return false

    api.post('pagamento/iniciarpagamento', data).then(r => {
      window.location.href = r.data.value.initPoint
    }).catch(err => {
      console.log('ERRO: ', err)
    });
  }

  return (<>
    <Form ref={formRef} onSubmit={salvarDados}>
      <div className="row">
        <div className="col-md-12 mb-60">
          {/* <h3>Finalização e Pagamento</h3> */}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <Textarea name="observacaoCarga" title="Observação da Carga" maxLength="256" style={{ height: "100px" }}></Textarea>


          <span errors={ErroTipoCarga}>{ErroTipoCarga} <br /></span>
          <SelectInput name="codigoTipoCargaEspecifica" required className="input" Placeholder="O que você vai enviar?" option={[{ value: 26, descricao: 'Documentos' }, { value: 37, descricao: 'Eletrônicos' }, { value: 25, descricao: 'Outros' }]} />
          <br />
          <div className="d-flex justify-content-center">
            <p className='text-center pt-20'>
              Lembre-se de anexar na encomenda, uma cópia da <b> Nota Fiscal</b> ou <a href={modeloDeclaracaoPDF} target="_blank" rel='noreferrer'> declaração de conteúdo</a>
              {/* Necessário no momento de despachar a encomenda, entregar a <b>Nota Fiscal</b> da encomenda. Em último caso, poderá anexar a declaração de conteúdo. */}
              {/* <a href={modeloDeclaracaoPDF} target="_blank" rel='noreferrer'>Baixar modelo declaração de conteúdo</a> */}
            </p>
          </div>
          <p><br /></p>
          <div className="d-flex justify-content-center">
            <div className="form-check">
              <span errors={ErroTermoObrigatorio}>{ErroTermoObrigatorio} <br /></span>
              <InputCheckbox name="bolTermosAceito" className="form-check-input" type="checkbox" id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Aceito os <Link to="/termosCondicoes">termos de transporte</Link>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="text-center pt-40">
            <button className="btn btn-primary btn-wide">Confirmar e Pagar</button>
            <span className='text-danger' id='erros'></span>
            {/* <br />
            <button type="submit" className="btn btn-link " onClick={() => props.setStepForm(3)}>Voltar</button> */}
          </div>
        </div>
      </div>
    </Form>
  </>)
}
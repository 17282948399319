import React, { useRef, useState } from 'react'
import { Form } from '@unform/web'
import { NotificationManager } from 'react-notifications';
// import Input from './../form/input'
// import MaskInput from './../form/maskInput'
import CepInput from '../form/cepInput'
import FloatInput from '../form/floatInput'
import Input from '../form/input'
import api from '../../services/api'
import { ChaveRecaptcha } from '../../services/utils'
import ReCAPTCHA from "react-google-recaptcha"
import ModalTrechoNaoAtendido from '../modalTrechoNaoAtendido';
// import Logo from "./../../assets/img/logo_branca.svg"

export default function CotacaoFormulario(props) {
  const { exportQuotationItens } = props;
  const formRef = useRef(null)
  const recaptchaRef = useRef()

  const [counting, setCounting] = useState(false)
  const [showModalTrechoNaoAtendido, setShowModalTrechoNaoAtendido] = useState(false)
  const [dadosTrechoNaoAtendido, setDadosTrechoNaoAtendido] = useState({})

  const handleSubmit = (data) => {

    data.origem = data.origem.replace(/\D/g, '')
    data.descricaoOrigem = document.getElementById("label-origem").value
    data.destino = data.destino.replace(/\D/g, '')
    data.descricaoDestino = document.getElementById("label-destino").value
    data.altura = data.altura.replace(/\D/g, '')
    data.comprimento = data.comprimento.replace(/\D/g, '')
    data.largura = data.largura.replace(/\D/g, '')
    data.peso = data.peso.replace(/\D/g, '').replace(/([\d]{2}$)/, '.$1')
    data.valorDeclarado = data.valorDeclarado.replace(/\D/g, '').replace(/([\d]{2}$)/, '.$1')

    api.post("Cotacao", data).then(response => {
      // console.log("Cotacao", response.data)
      let r = response.data;

      if (r.tipoMensagem !== 0) {
        setShowModalTrechoNaoAtendido(true)
        setDadosTrechoNaoAtendido(r)
      }
      else
        exportQuotationItens(r.objRetorno, data)

      setCounting(false)
    }).catch(err => {
      NotificationManager.error('Não foi possível concluir a solicitação');
      console.log("ERRO: ", err)
      setCounting(false)
    })
  }

  const onSubmitWithReCAPTCHA = async (data) => {
    setCounting(true)
    const token = await recaptchaRef.current.executeAsync()
    if (token) data.RecaptchaResponse = token.toString()
    handleSubmit(data);
    // apply to form data
  }

  return <div>
    <div className="row">
      <div className="col-md-12 mb-40 text-center">
        {/* <p>Faça uma Cotação</p> */}
        <h4>Faça Sua Cotação</h4>
      </div>
    </div>
    <Form ref={formRef} onSubmit={onSubmitWithReCAPTCHA}>
      <div className="row justify-content-md-center">
        <div className="col-md-10">
          <Input name="email" title="E-mail" type="text" required />
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-md-5">
          <CepInput name="origem" title="Cep Origem" required />
        </div>
        <div className="col-md-5">
          <CepInput name="destino" title="Cep Destino" required />
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-md-2 coll-md-offset-1 col-6">
          <FloatInput name="largura" max="100" min="1" title="Largura (cm)" required decimal="0" />
        </div>
        <div className="col-md-2 col-6">
          <FloatInput name="comprimento" max="100" min="1" title="Comprimento (cm)" required decimal="0" />
        </div>
        <div className="col-md-2 col-6">
          <FloatInput name="altura" max="100" min="1" title="Altura (cm)" required decimal="0" />
        </div>
        <div className="col-md-2 col-6">
          <FloatInput name="peso" max="10" min="0.05" title="Peso (kg)" required step=".01"/>
        </div>
        <div className="col-md-2">
          <FloatInput name="valorDeclarado" type="text" title="Valor Declarado (R$)" required />
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-md-10 coll-md-offset-1 d-grid">
          <button type="submit" className="btn btn-primary" onClick={() => { }}>{counting ? <i className="fas fa-spinner fa-spin"></i> : 'Calcular'}</button>
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={ChaveRecaptcha}
      // onChange={Enviar}
      />
    </Form>
    <ModalTrechoNaoAtendido show={showModalTrechoNaoAtendido} close={() => setShowModalTrechoNaoAtendido(false)} dados={dadosTrechoNaoAtendido} />
  </div>
}
import { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import api from '../../services/api'
import Header from '../../components/header'
import { Destinatario, Remetente } from '../../components/checkoutFormulario'
import { FormatarValor } from '../../services/utils'
import Footer from '../../components/footer'
// import efacil from './../../assets/media/efacil.svg'
import modeloDeclaracao from '../../assets/media/Formulario_Declaracao_de_Conteudo_A4.docx'

export default function DetalhesPosPagamento(props) {
  // const { guidCheckout } = useParams();
  const guidCheckout = props.match.params.guidCheckout
  const [dadosCheckout, setDadosCheckout] = useState({})


  useEffect(() => {
    api.post(`Cotacao/GetDadosCheckout`, { "Guid": guidCheckout }).then(response => {
      // console.log(response.data)
      setDadosCheckout(response.data)
    }).catch(err => {
      console.log("ERRO: ", err)
      // setCounting(false)
    })
  }, [guidCheckout])

  let getLinkPagamento = () => {
    api.post(`Pagamento/GetLinkPagamento`, { "Guid": guidCheckout }).then(response => {
      window.open(response.data, '_blank');
    }).catch(err => {
      console.log("ERRO: ", err)
    })
  }

  return (
    <div>
      <Header titulo={<TitutoHeader />} />
      <section style={{ marginTop: '-215px', position: 'relative' }}>
        <div className="container">
          <div className="box-white">
            <div>
              <div className="row">
                <div className="col-md-12 mb-60 text-center">
                  {/* <p>Transação: 123456</p> */}
                  {
                    dadosCheckout.codigoStatus === 3 ?
                      <>
                        <h3 className="d-none d-md-block">Pagamento Concluído</h3>
                        <h4 className="d-block d-md-none">Pagamento Concluído</h4>
                        {/* <i className="fas fa-check fa-5x text-azulpontual pb-30 pt-30"></i> */}
                        {/* <h4>Pronto, agora é só aguardar.</h4> */}
                        <p>Enviamos estes detalhes para o seu e-mail</p>
                        <p className='mb-1'>Rastreio</p>
                        <h4>202100000</h4>
                      </>
                      :
                      <>
                        <h3 className="d-none d-md-block">Pagamento Pendente</h3>
                        <h4 className="d-block d-md-none">Pagamento Pendente</h4>
                        <i className="far fa-clock fa-5x text-azulpontual pb-30 pt-30"></i>
                        <h4>Aguardando pagamento para continuarmos.</h4>
                        <p>Aguardamos a confirmação de pagamento para efetuar a coleta da carga.</p>
                        <button className="btn btn-primary mt-30" onClick={getLinkPagamento}>TELA DE PAGAMENTO</button>
                      </>
                  }
                </div>
              </div>
            </div>
            {/* <Quote /> */}
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mb-20">Informações da Carga</h4>
                      <p>
                        {!dadosCheckout.cargas || <span>Dimensões <b className="text-vermelhopontual"> L {dadosCheckout.cargas[0].largura}cm  x C {dadosCheckout.cargas[0].comprimento}cm x A {dadosCheckout.cargas[0].altura}cm</b>, com o peso de <b className="text-vermelhopontual">{dadosCheckout.cargas[0].peso}kg</b> e valor declarado de <b className="text-vermelhopontual">R$ <FormatarValor valor={dadosCheckout.cargas[0].valorDeclarado} tipo="BRL" /></b>.</span>}<br />
                        <span>{/*Serviço solicitado <b className="text-vermelhopontual">{dadosCheckout.servico}</b> */}Com o prazo de até <b className="text-vermelhopontual">{dadosCheckout.prazo} dias corridos a partir do dia [dia da confirmação do pagamento],</b>  no valor de <b className="text-vermelhopontual">R$ <FormatarValor valor={dadosCheckout.valor} tipo="BRL" /></b></span>.
                      </p>
                      <a href={modeloDeclaracao} target="_blank" rel='noreferrer'><span></span>Baixar modelo declaração de conteúdo</a>
                    </div>
                  </div>
                  <div className="d-none d-md-block">
                    <p><br /></p>
                  </div>
                  <div className="row">
                    <Remetente dadosCheckout={dadosCheckout} setStepForm={4} bolBasico />
                    <Destinatario dadosCheckout={dadosCheckout} setStepForm={4} bolBasico />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <Footer />
    </div>
  )
}

const TitutoHeader = () => {
  return <>
    Você viu <br />como é fácil?
  </>
}
import axios from 'axios';
import storage from './storage';

const api = axios.create({
  // headers: {'Content-Type': 'application/json'}
  headers: { 'Content-Type': 'application/json' },
  baseURL: 'https://www.hom.api.cargo.srv.br/',
  // baseURL: 'http://10.61.1.155:3002/',  
  //  baseURL: 'https://localhost:50000/'
  // baseURL: 'https://api.pontualcargo.com.br/'
});

api.interceptors.request.use((config) => {
  const url = config.url || '';

  if (!url.endsWith('login')) {
    const token = storage.getToken();
    if (token && token.length > 0) { config.headers.Authorization = `Bearer ${token}`; }
  }

  return config;
});

// api.interceptors.response.use(response => {
//   return response;
// }, error => {
//   console.log(error)

//   if (error.response.status === 500) {
//     NotificationManager.info('Info message');
//   }
//   return error;
// });

export default api;
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import api from './../../services/api'
import Header from '../../components/header'
import CheckoutFormulario from '../../components/checkoutFormulario'
import Footer from '../../components/footer'
import ModalSouRemetenteOuDestinatario from '../../components/modalSouRemetenteOuDestinatario'

export default function Checkout(props) {
  // const { guidCheckout } = useParams();
  const guidCheckout = props.match.params.guidCheckout
  const [stepForm, setStepForm] = useState(2)
  const [dadosCheckout, setDadosCheckout] = useState({})

  useEffect(() => {
    api.post(`Cotacao/GetDadosCheckout`, { "Guid": guidCheckout }).then(response => {
      console.log("AQUI", response.data)
      setDadosCheckout(response.data)
    }).catch(err => {
      console.log("ERRO: ", err)
      NotificationManager.error('Não foi possível recuperar os dados da cotação');
      // setCounting(false)
    })
  }, [guidCheckout])

  const preencherEnderecoComMeusDados = (meusDados, bolRemetente) => {
    if (bolRemetente)
      preencherDadosRemetente(meusDados)
    else
      preencherDadosDestinatario(meusDados)
  }

  const preencherDadosRemetente = (meusDados) => {
    document.getElementsByName("RemetenteNome")[0].value = meusDados.nome
    document.getElementsByName("RemetenteCPFCNPJ")[0].value = meusDados.cpfcnpj
    document.getElementsByName("RemetenteLogradouro")[0].value = meusDados.logradouro
    document.getElementsByName("RemetenteNumero")[0].value = meusDados.numero
    document.getElementsByName("RemetenteComplemento")[0].value = meusDados.complemento
    document.getElementsByName("RemetenteBairro")[0].value = meusDados.bairro
  }

  const preencherDadosDestinatario = (meusDados) => {
    let dados = dadosCheckout
    dados.destinatarioNome = meusDados.nome
    dados.destinatarioCpfcnpj = meusDados.cpfcnpj
    dados.destinatarioLogradouro = meusDados.logradouro
    dados.destinatarioNumero = meusDados.numero
    dados.destinatarioComplemento = meusDados.complemento
    dados.destinatarioBairro = meusDados.bairro
    setDadosCheckout(dados)
  }

  return (
    <div>
      <Header titulo={<TitutoHeader />} />
      <section style={{ marginTop: '-215px', position: 'relative' }}>
        <div className="container">
          <div className="box-white">
            <div>
              <div className="row">
                <div className="col-md-12 mb-60 text-center">
                  {/* <p>É fácil transportar com a Pontual</p> */}
                  <h3>Finalização e Pagamento</h3>
                </div>
              </div>
            </div>
            {/* <Quote /> */}
            <div className="row justify-content-md-center">
              <div className="col-md-10">
                <CheckoutFormulario step={stepForm} setStepForm={setStepForm} guid={guidCheckout} dadosCheckout={dadosCheckout} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <Footer />
      {
        (dadosCheckout && dadosCheckout.cepOrigem === dadosCheckout.cepEnderecoUsuario && !dadosCheckout.remetenteCpfcnpj) || (dadosCheckout &&  dadosCheckout.cepDestino === dadosCheckout.cepEnderecoUsuario && !dadosCheckout.destinatarioCpfcnpj) ? 
        <ModalSouRemetenteOuDestinatario cepRemetente={dadosCheckout.cepOrigem} cepDestinatario={dadosCheckout.cepDestino} cepUsuario={dadosCheckout.cepEnderecoUsuario} callback={preencherEnderecoComMeusDados} /> : null
      }
      
    </div>
  )
}

const TitutoHeader = () => {
  return <>
    Veja como é fácil <br /> enviar sua encomenda
    {/* Pronto pra ver <br />como é fácil? */}
  </>
}
import { Link } from 'react-router-dom'
import ModalLogin from '../../components/modalLogin'
import ModalCadastroUsuario from '../../components/modalCadastroUsuario'
import LgpdCookies from '../../components/lgpdCookies'
import BoxEntrarEmContato from '../../components/boxEntrarEmContato'
import LogoPontual from '../../assets/media/logo-atualizada.png'

export default function Footer() {
  return <footer className="footer">
    <hr />
    <div className="footer-address">
      <div className="container">
        <div className="row">
          <div className="col-md-6 pb-50 pt-50">
            <div className='d-none d-md-block d-lg-block'>
              <p><br /></p>
              <p><br /></p>
              <p><br /></p>
              <p><br /></p>
            </div>
            {/* <div className='text-white'>By</div> */}
            <img src={LogoPontual} alt="Logo Pontual" width={200} />
            <div className="pl-65 pt-10">
              <a href="https://www.pontualcargo.com.br" class="text-decoration-none text-secondary" target="_blank" rel="noreferrer">Grupo Pontual</a>
            </div>
          </div>
          <div className="col-md-6 pb-50 pt-50">
            <BoxEntrarEmContato></BoxEntrarEmContato>
          </div>
          {/* <div className="col-sm-4">
            <Sao></Sao>
          </div> */}
        </div>
      </div>
    </div>
    <div className="allRight text-center">
      <hr className="mb-20" />
      <div className="container">
        {/* <p className="a"></p> */}
        <p className="pb-15 m-0">
          <a href='/'><i className="fab fa-facebook-f"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='/'><i className="fab fa-instagram"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='/'><i className="fab fa-twitter"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='/'><i className="fab fa-tiktok"></i></a>&nbsp;&nbsp; | &nbsp;&nbsp;
          © {new Date().getFullYear()} éFácil <i className="fas fa-truck"></i> Todos os direitos reservados -
          <span className="a"> <Link to="/termosCondicoes">Termos e Condições</Link> &nbsp;&nbsp; | &nbsp;&nbsp; <Link to="/politicaPrivacidade">Política de Privacidade</Link></span>
        </p>
      </div>
    </div>
    <LgpdCookies />
    <ModalLogin />
    <ModalCadastroUsuario />
  </footer>
}
import { useState } from 'react'
import api from './../../services/api'

export default function ModalTrack() {
  const [dadosRastreio, setDadosRastreio] = useState({})

  const GetDadosRastreamento = async () => {
    let localizador = document.getElementById('localizador').value

    if (localizador.length >= 6) {
      await api.get(`rastreio/${localizador}`)
        .then(response => {
          console.log(response.data)
          setDadosRastreio(response.data)
          //   return response.data
        }).catch(err => {
          console.log("ERRO: ", err)
        })
    }
  }

  return <div className="modal fade show" id="modalTrack">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body pt-30 pr-30 pb-30 pl-30">
          <div className="mb-60">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3 className="text-center">Minha carga</h3>
            <p className="text-center">Lorem ipsum dolor sit amet, consectetur</p>
          </div>
          <div className="row align-items-center justify-content-around">
            <div className="col-md-12 d-grid">
              <input type="text" id="localizador" className="input input-sm" placeholder={new Date().getFullYear() + "000000 - 00"} />
              <button className="btn btn-primary btn-block btn-sm" onClick={GetDadosRastreamento}>RASTREAR</button>
            </div>
            <div className="col-md-12">
              <table className="table mt-40">
                {/* <thead className="table-dark">
                  ...
                </thead> */}
                <tbody>
                  {
                    dadosRastreio.historico && dadosRastreio.historico.map(x => {
                      return (
                        <tr>
                          <td>24 Jan 2021 15:53</td>
                          <td>Carga Desembarcada em BRASILIA/DF</td>
                        </tr>
                      )
                    })
                  }

                  <tr>
                    <td>23 Jan 2021 15:53</td>
                    <td>Carga Embarcada em SAO PAULO/SP</td>
                  </tr>
                  <tr>
                    <td>23 Jan 2021 13:13</td>
                    <td>Carga Validada</td>
                  </tr>
                  <tr>
                    <td>23 Jan 2021 12:52</td>
                    <td>Carga Coletada <b>(com João Manoel)</b></td>
                  </tr>
                  <tr>
                    <td>23 Jan 2021 11:53</td>
                    <td>Saiu para Coleta da CargaSaída</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

}
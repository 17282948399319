import { useState, useEffect, useRef } from 'react'
import { NotificationManager } from 'react-notifications';
import Header from '../../components/header'
import Footer from '../../components/footer'
import api from '../../services/api'
import { formataDateTime, ChaveRecaptcha } from '../../services/utils'
import ReCAPTCHA from "react-google-recaptcha"

export default function RastreioEncomendas(props) {
  const [listaEncomenda, setListEncomenda] = useState([])
  const [bolListaVazia, setBolListaVazia] = useState(false)
  const recaptchaRef = useRef()
  const rastreio = props.match.params.rastreio

  useEffect(() => {

    const getToken = async () => {
      const token = await recaptchaRef.current.executeAsync()

      api.post(`Rastreio`, {RecaptchaResponse: token, Localizador: rastreio}).then(response => {
        setListEncomenda(response.data)
        if (!response.data.length) {
          setBolListaVazia(true)
        }
      }).catch(err => {
        setBolListaVazia(true)
        NotificationManager.error('Não foi possível carregar, tente novamente mais tarde!');
      })
    }

    getToken().catch(console.error);
    // data.RecaptchaResponse = token.toString();    
  })

  const redirecionarParaRastreio = (e) => {
    props.history.push(`/rastreioencomendas/${e.target.parentElement.children[1].value}`)
  }

  return (
    <div style={{ backgroundColor: '#f1f4ff' }}>
      <Header centralizar xsmall />
      <section>
        <div className="container mt-150 mb-150">
          <div className="row">
            <div className="col-md-12 col-lg-8 offset-lg-2">
              <div>
                <h3 className="mb-3">Rastreio de Encomendas</h3>
              </div>
              <div className="pedidos" >
                <div className="pedidos-head">
                  <span>Rastreio:</span>
                  <input className="input input-sm" name="rastreador" placeholder="2021000000" maxLength={10} />
                  <button className="btn btn-primary btn-sm ml-10" onClick={e => redirecionarParaRastreio(e)}>Pesquisar</button>
                </div>
                <div className="table-div" style={{ display: bolListaVazia ? "none" : "block" }}>
                  <div className="table-box-titulo">
                    <div>Data Evento</div>
                    <div>Hora</div>
                    <div>Descrição</div>
                  </div>

                  {
                    listaEncomenda.map((x, y) => (
                      <div key={y} className="table-box cursor">
                        {/* <div>#{("0000" + (y + 1)).slice(-4)}</div> */}
                        <div>{formataDateTime(new Date(x.dataHoraEvento)).date}</div>
                        <div>{formataDateTime(new Date(x.dataHoraEvento)).time}h</div>
                        <div>
                          {x.descricaoEvento}<br />
                          <small>{x.observacao}</small>
                        </div>
                      </div>
                    ))
                  }

                </div>
              </div>
            </div>
            <div className="col-md-8 offset-md-2">
              <div className="text-center box-white" id="mensagem" style={{ display: bolListaVazia ? "block" : "none" }}>
                <h5>A carga referente ao localizador</h5>
                <h4>{props.match.params.rastreio}</h4>
                <p className="mb-50">Encontra-se em processamento</p>
                <small className="text-azulpontual">Caso não haja alteração no status dentro de alguns dias, favor entrar em contato com o éFácil</small>
              </div>
            </div>
          </div>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={ChaveRecaptcha}
        // onChange={Enviar}
        />
      </section>
      <Footer />
    </div>
  )
}
import Nav from './../../components/nav'
// import efacil from './../../assets/media/efacil.svg'

export default function Header(props) {
  let height = '750px';

  if (props.small)
    height = '400px'

  if (props.xsmall)
    height = '150px'

  if (props.none)
    height = '0'

  let style = {}

  if (props.centralizar)
    style.textAlign = 'center'

  return <div className="header" style={{ height: height }}>
    <div className="head">
      <Nav />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="destaque-banner">

              {/* <div className="frase">
                Pronto pra ver <br />como
                <img src={efacil} alt="eFácil " /> ?
              </div> */}
              <div className="frase" style={style}>
                {props.titulo}
              </div>
              {/* <div className="sub-frase">
                Uma nova e melhor experiência no transporte de <br />
                encomendas está chegando. Espere algo melhor.
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
import jwt from 'jsonwebtoken'

const setToken = (token) => {
  localStorage.setItem('token', token)
}

const clearToken = () => {
  localStorage.removeItem('token')
}

const getTokenDecoded = (token) => {
  const decoded = jwt.decode(token || '', { complete: true })

  return decoded
}

const isTokenExpired = (token) => {
  // let hasTokenExpired = false
  let payload = getTokenDecoded(token)?.payload

  if (payload && Date.now() >= payload.exp * 1000)
    return true
  return false
  // jwt.verify(token, '', (err) => {
  //   const erro = err || { name: '' }
  //   hasTokenExpired = erro.name === 'TokenExpiredError'
  // })

  // return hasTokenExpired
}

const getToken = () => {
  let token = localStorage.getItem('token') || ''

  if (isTokenExpired(token)) {
    clearToken()
    token = ''
  }

  return token
}

const isAuthenticated = () => {
  const token = getToken()

  return !!token
}

const storage = {
  getToken,
  setToken,
  clearToken,
  isAuthenticated,
  getTokenDecoded,
}

export default storage
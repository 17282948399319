import Header from '../../components/header'
import Footer from '../../components/footer'

export default function TermosCondicoes() {
  return (
    <div>
      <Header titulo="Termos e Condições" centralizar small/>
      <section>
        <div className="container mt-150 mb-150">
          <div className="row">
            <div className="col-md-12 col-lg-8 offset-lg-2">
              {/* <h1 className="text-center mb-100">Termos e Condições</h1> */}
              <p>1- A Pontual não aceita para transporte os seguintes produtos: papel moeda, inflamáveis, explosivos, gases tóxicos, irritantes, oxidantes, corrosivos e ácidos,
                elementos radiotivos, agentes venosos, agentes etiológicos, os produtos proibidos por lei (tóxicos/estimulantes/derivados), cargas perigosas não
                mencionadas, mas que possam colocar em risco a vida das pessoas e as aeronaves.</p>
              <p>2- Todo produto aceito para transporte deve se fazer acompanhar da respectiva Nota Fiscal ou documento aceito pela Fiscalização Fazendária.</p>
              <p>3- O remetente será responsabilizado judicialmente pela declaração de falso conteúdo.</p>
              <p>4- Embalagem não adequada para transporte será recusada. A Pontual disponibiliza embalagens mediante pagamento por cada unidade requerida.</p>
              <p>5- Os valores poderão ser modificados após a conferência física do Peso e Dimenões dos volumes no ato do embarque.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
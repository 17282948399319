import React, { createContext, useState, useEffect } from 'react'
import storage from './storage'
//import api from './api'

const Context = createContext()

function ContextApp({ children }) {
  // const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userContext, setUserContext] = useState('')
  const [authenticatedContext, setAuthenticatedContext] = useState('')

  const setContext = (user, bolAuthenticated) => {
    setUserContext(user)
    setAuthenticatedContext(bolAuthenticated)
  }

  useEffect(() => {
    let token = storage.getToken()
    if (token) {
      //api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthenticatedContext(true)
      setUserContext(storage.getTokenDecoded(token).payload.lgn)
    }
    setLoading(false)
  }, [])

  if (loading) {
    return <></>
  }

  return <Context.Provider value={{ userContext, authenticatedContext, setContext }}>
    {children}
  </Context.Provider>
}

export { Context, ContextApp }
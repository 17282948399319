import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

export default function SelectInput({ name, Placeholder, option, ...rest }) {
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        // ref.setInputValue(value);
        ref.value = value
      },
      clearValue(ref) {
        // ref.setInputValue('');
        ref.value = ''
      }
    })
  }, [fieldName, registerField])

  

  return <select ref={inputRef} name={fieldName} defaultValue={defaultValue} {...rest}>
    <option value={0}>{Placeholder}</option>
    {
      option && option.map((x, y) => {
        return <option key={y} value={x.value} >{x.descricao}</option>
      })
    }
  </select>

  // return <input ref={inputRef} defaultValue={defaultValue} {...rest} />
}
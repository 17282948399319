// import { useState } from "react"



export default function LgpdCookies() {
  const cookie = localStorage.getItem('bolCookieAceito')
  //let [bolCookieAceito, setBolCookieAceito] = useState(cookie === 'true')

  var aceitarUsoCookie = () => {
    var element = document.getElementById("lgpd-cookies");
    element.classList.add("animate__fadeOutDown");
    localStorage.setItem('bolCookieAceito', 'true')
    //setBolCookieAceito(true)
  }

  return <div id="lgpd-cookies" className={`animate__animated lgpd-cookies ${cookie ? 'd-none' : ''}`}>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <p>Usamos cookies <br /> Armazenamos dados temporariamente para personalizar e melhorar sua experiência de navegação na nossa plataforma. Ao continuar usando o <b>éFácil</b>, você concorda com isso.</p>
          <div className="text-right">
            <button className="btn btn-dark btn-sm" onClick={aceitarUsoCookie}>Concordar </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}
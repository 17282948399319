import { useState } from 'react'
// import { useHistory } from "react-router-dom";
import Header from './../../components/header'
import CotacaoFormulario from '../../components/cotacaoFormulario'
import PrecosPorServicoCotado from '../../components/precosPorServicoCotado'
// import Testemunhos from './../../components/testemunhos'
import ModalTrack from './../../components/modalTrack'
import Footer from './../../components/footer'
import { TransportarCotacao } from './../../services/utils'
import IconeBox from './../../../src/assets/media/icon-box.png'
import IconeCar from './../../../src/assets/media/icon-car.png'
import IconeClock from './../../../src/assets/media/icone-clock.png'
// import efacil from './../../assets/media/logo-branca.png'
// import api from './../../services/api'

export default function Home(props) {
  const [quotationItens, setQuotationItens] = useState([])
  const [parametrosParaConsulta, setParametrosParaConsulta] = useState({})

  const getQuotationItens = (responseData, data) => {
    setQuotationItens(responseData)
    setParametrosParaConsulta(data)
  }

  const transportarCotacao = (codigoServico) => {
    parametrosParaConsulta.codigoServico = codigoServico
    TransportarCotacao(parametrosParaConsulta)
    // api.post("Cotacao/TransportarCotacao", parametrosParaConsulta).then(response => {
    //   console.log(response.data.guid)
    //   history.push(`/checkout/${response.data.guid}`);

    //   // exportQuotationItens(response.data)
    //   // setCounting(false)
    // }).catch(err => {
    //   console.log("ERRO: ", err, parametrosParaConsulta)
    //   // setCounting(false)
    // })
  }

  const redirecionarParaRastreio = () => {
    props.history.push(`/rastreioencomendas/${document.getElementById("idRastreio").value}`)
  }

  const salvarNoLocalStorage = (codigoServico) => {
    parametrosParaConsulta.codigoServico = codigoServico
    localStorage.setItem("parametrosParaConsulta", JSON.stringify(parametrosParaConsulta));
  }

  return (
    <div>
      <div className="bg-azul">
        <Header titulo={<TitutoHeader />} />
        {/* Formulario de Cotação */}
        <section className="quote">
          <div className="container">
            <div className="box-white">
              <CotacaoFormulario exportQuotationItens={getQuotationItens} />
              <div className="row justify-content-md-center">
                <div className="col-md-10">
                  <div className="row" style={quotationItens.length ? { 'marginTop': '70px' } : null}>
                    {
                      quotationItens.map((x, i) => {
                        return <div key={i}>
                          <PrecosPorServicoCotado
                            codigoServico={x.serviceCode}
                            description={x.serviceDescription}
                            deadline={x.deadline} price={x.price}
                            transportarCotacao={transportarCotacao}
                            salvarNoLocalStorage={salvarNoLocalStorage}
                          />
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Passo a Passo */}
        <section className="bg-azul pt-200 pb-100" style={{ marginTop: "-121px" }}>
          <div className="container">
            <div className="row pt-70">
              <div className="col-md-12 mb-60 text-center">
                {/* <p>Nossos serviços</p> */}
                <h3 className="text-white">Nunca foi tão fácil enviar sua encomenda</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-20">
                <div className="text-center pt-50 pb-50">
                  <img src={IconeBox} alt="Caixa Encomenda" />
                  <p><br /></p>
                  <h5 className="text-white">Faça sua cotação</h5>
                  <p className="pr-30 pl-30 text-white" >É só preencher os campos no formulário acima.</p>
                </div>
              </div>
              <div className="col-md-4 mb-20">
                <div className="text-center pt-50 pb-50">
                  <img src={IconeCar} alt="Carrinho" />
                  <p><br /></p>
                  <h5 className="text-white">Confirmado o pagamento</h5>
                  <p className="pr-30 pl-30 text-white" >Separe sua encomenda com a NF ou declaração do que está enviando.</p>
                </div>
              </div>
              <div className="col-md-4 mb-20">
                <div className="text-center pt-50 pb-50">
                  <img src={IconeClock} alt="Prazo" />
                  <p><br /></p>
                  <h5 className="text-white">Agora é só esperar</h5>
                  <p className="pr-30 pl-30 text-white" >Coletaremos na sua porta e entregaremos na porta do destinatário.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>      
      {/* Sobre nós */}
      <section className="mt-200 mb-200">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="mb-40">Quem somos nós</h3>
              <h5 className="mb-30">Uma empresa que veio simplificar o procedimento de transporte porta a porta.</h5>
              <p style={{textAlign: "justify"}}>Ao analisarmos o mercado, notamos uma oportunidade de simplificar o transporte de cargas e encomendas.              	
                 Sendo assim, a Pontual criou o serviço éFácil, que visa dar celeridade nesse processo, facilitando o dia a dia de pessoas físicas e empresas que necessitam de um serviço ágil.
              Para isso, precisamos de algumas informações e dois minutinhos do seu dia, o resto é conosco!
                </p>
            </div>
            <div className="col-md-5 offset-md-1">
              <div className="bloco-image-1">
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="track pt-200 pb-200">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-6">
              <h2 className="text-center">Rastrear</h2>
              <div className="input-group mb-3">
                <input type="text" className="form-control" id="idRastreio" placeholder="2021210000" />
                <button className="btn btn-outline-light" type="button" onClick={() => redirecionarParaRastreio()}>Rastrear</button>
              </div>
              <div>
                <p>ACOMPANHE O STATUS DE SEU ENVIO EM TEMPO REAL</p>
              </div>
            </div>
          </div>
        </div>
      </section>  

      {/* Modals */}
      <ModalTrack />

      {/* Rodape */}
      <Footer />
    </div>
  )
}

const TitutoHeader = () => {
  return <>
    Veja como é fácil <br /> enviar sua encomenda
    {/* Pronto pra ver <br />como é fácil? */}
  </>
}
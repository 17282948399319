import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
// import ReactInputMask from 'react-input-mask';

export default function FloatInput({ name, title, pre, pos, decimal, ...rest }) {
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)
  const preValue = pre ? pre : ''
  const posValue = pos ? pos : ''
  const decimalValue = decimal ? decimal : '2'

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.value = value
        // ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.value = ''
      }
    })
  }, [fieldName, registerField])

  const toFloatValue = (value, e) => {
    let v = (value).replace(/\D/g, '')
    let reg = new RegExp(`([0-9]{${decimalValue}})$`)
    e.target.value = preValue + v.replace(reg, `${Number(decimalValue) === 0 ? '' : '.'}$1`) + posValue
    // console.log(value, e)
  }

  // return <input ref={inputRef} defaultValue={defaultValue} {...rest} onChange={(event) => toFloatValue(event.target.value, event)} />

  return <div className="form-floating mb-3">
    <input ref={inputRef} defaultValue={defaultValue} {...rest} onChange={(event) => toFloatValue(event.target.value, event)} id={fieldName} type="Number" className="form-control" placeholder={title} />
    <label htmlFor={fieldName}>{title}</label>
  </div>
}
import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

export default function Textarea({ name, title, ...rest }) {
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        // ref.setInputValue(value);
        ref.value = value
      },
      clearValue(ref) {
        // ref.setInputValue('');
        ref.value = ''
      }
    })
  }, [fieldName, registerField])

  return <div className="form-floating mb-3">
    <textarea name={fieldName} ref={inputRef} defaultValue={defaultValue} id={fieldName} {...rest} placeholder={title} className="form-control"></textarea>    
    <label htmlFor={fieldName}>{title}</label>
  </div >;
}
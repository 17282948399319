import { Switch, Route, Redirect } from 'react-router-dom'
import IsAuthenticated from './services/auth'
import Home from './pages/home'
import Checkout from './pages/checkout'
import DetalhesPosPagamento from './pages/detalhesPosPagamento'
import Pedidos from './pages/pedidos'
import PoliticaPrivacidade from './pages/politicaPrivacidade'
import TermosCondicoes from './pages/termosCondicoes'
import RecuperarSenha from './pages/recuperarSenha'
import NovaSenha from './pages/novaSenha'
import RastreioEncomendas from './pages/rastreioEncomendas'

export default function MainRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <PrivateRoute path="/checkout/:guidCheckout" component={Checkout} />
      <PrivateRoute path="/detalhes/:guidCheckout" component={DetalhesPosPagamento} />
      <PrivateRoute path="/meuspedidos" component={Pedidos} />
      <Route path="/politicaPrivacidade" component={PoliticaPrivacidade} />
      <Route path="/termosCondicoes" component={TermosCondicoes} />
      <OffRoute path="/autenticacao/recuperarSenha" component={RecuperarSenha} />
      <OffRoute path="/autenticacao/novaSenha/:identificador/:securityStamp" component={NovaSenha} />
      <Route path="/rastreioencomendas/:rastreio" component={RastreioEncomendas} />
    </Switch>
  )
}

function PrivateRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={props => (
    IsAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )
  )} />
}

function OffRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={props => (
    !IsAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )
  )} />
}
import { NotificationManager } from 'react-notifications';
import api from './../../services/api'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { DesativarBotaoCarregando } from '../../services/utils'
import { useEffect } from 'react';

export default function RecuperarSenha() {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const solicitarRecuperacaoSenha = () => {
    let Username = document.getElementById("email").value

    DesativarBotaoCarregando("btnEnviar", "...")

    api.post(`User/RecuperacaoSenha`, { Username }).then(response => {
      console.log(response)
      document.getElementById("formSolicitacao").style.display = "none";
      document.getElementById("enviado").style.display = "block";
    }).catch(err => {
      console.log("ERRO: ", err)
    DesativarBotaoCarregando("btnEnviar", "Tentar novamente")
      NotificationManager.error('Não foi possível concluir a solicitação');
    })
  }

  return (
    <div style={{ backgroundColor: '#f1f4ff' }}>
      <Header titulo="" centralizar none />
      {/* <h2 className="text-center pt-150">Recuperação de Senha</h2> */}
      <section>
        <div className="container pt-150 mb-150">
          <div className="row">
            <div className="col-md-4 offset-md-4">
              <div className="box-white">
                <h4 className="text-center pb-50">Recuperação de Senha</h4>
                <div id="formSolicitacao">
                  <input type="text" className="input" id="email" placeholder="Infome seu e-mail" />
                  <button className="btn btn-primary btn-block" id="btnEnviar" onClick={solicitarRecuperacaoSenha}>Enviar</button>
                </div>
                <div className="text-center" id="enviado" style={{ display: 'none' }}>
                  <i className="far fa-envelope-open fa-8x text-azulpontual"></i>
                  <br />
                  <br />
                  <h4>E-mail enviado!</h4>
                  <p>Verifique sua caixa de e-mail para proceguir com a recuperação de senha</p>
                </div>
              </div>
              <div className="text-center mt-30">
                <a href="/">Voltar para a Home</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
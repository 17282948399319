import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

export default function CpfCnpjInput({ name, title, ...rest }) {
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        // ref.setInputValue(value);
        ref.value = value
      },
      clearValue(ref) {
        // ref.setInputValue('');
        ref.value = ''
      }
    })
  }, [fieldName, registerField])

  const handleText = (e) => {
    let v = (e.target.value).replace(/\D/g, '').substr(0, 14)

    //console.log(v)
    if (v.length <= 11)
      e.target.value = v.replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/g, '$1.$2.$3-$4')
    else
      e.target.value = v.replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/g, '$1.$2.$3/$4-$5')

    // e.selectionEnd = 10000;

  }

  return (
    <div className="form-floating mb-3">
      <input name={fieldName} ref={inputRef} defaultValue={defaultValue} id={fieldName} {...rest} placeholder={title} onChange={e => { handleText(e) }} className="form-control" />
      <label htmlFor={fieldName}>{title}</label>
    </div >
  )
  // <input name={fieldName} ref={inputRef} defaultValue={defaultValue} {...rest} onChange={e => { handleText(e) }} />
}
import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import api from './../../services/api'
import { Authenticate } from './../../services/auth'
import { Context } from './../../services/contextApp'
import { TransportarCotacao } from './../../services/utils'

export default function ModalLogin() {
  let pathName = window.location.pathname
  let history = useHistory()

  const { setContext } = useContext(Context)

  const [user, setUser] = useState("")
  const [pass, setPass] = useState("")
  const [msg, setMsg] = useState("")
  const [bolLogging, setBolLogging] = useState(false)

  useEffect(() => {
    let _inputUser = document.getElementById('user')
    let _modal = document.getElementById('modalLogin')

    _modal.addEventListener('shown.bs.modal', function () {
      _inputUser.focus()
    })
  }, [])

  const login = async (_user, _pass, event) => {
    event.preventDefault();
    setBolLogging(true)

    if (!_user || !_pass) {
      setMsg('Necessário informar usuário e senha.');
      return;
    }

    const response = await Authenticate(_user, _pass)

    if (!response) {
      setMsg('Ocorreu um erro inesperado ao tentar realizar autenticação.')
      setBolLogging(false)
      return
    }

    if (response.status === 200) {
      document.getElementById('modalLogin').click()
      setContext(_user, true)

      //SALVAR COTACAO
      if (localStorage.getItem('parametrosParaConsulta') != null) {
        TransportarCotacao(JSON.parse(localStorage.getItem('parametrosParaConsulta')))
        localStorage.removeItem('parametrosParaConsulta')
      }

      if (pathName === '/autenticacao/recuperarSenha')
        history.push("/")

    } else if (response.status === 401) {
      setMsg(response.data.message)
    } else {
      setMsg('Ocorreu um erro inesperado ao tentar realizar autenticação.')
    }
    //console.log(response)
    setBolLogging(false)
  }

  return <div className="modal fade" id="modalLogin">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body pt-30 pr-30 pb-30 pl-30">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="mb-20">
            <h3 className="text-center">Já tem cadastro conosco?</h3>
            <p className="text-center">Efetue seu login para prosseguir com o transporte</p>
            <div className="text-danger mb-20 text-center"><small>&nbsp;{msg}&nbsp;</small></div>
          </div>
          <div className="row align-items-center justify-content-around">
            <div className="col-md-5 d-grid">
              <form>
                <input type="text" id="user" onChange={(event) => setUser(event.target.value)} className="input input-sm" placeholder="E-mail" />
                <input type="password" autoComplete="on" onChange={(event) => setPass(event.target.value)} className="input input-sm" placeholder="Senha" />
                <button type="submit" className="btn btn-primary btn-block btn-sm" onClick={(event) => login(user, pass, event)}>
                  {bolLogging ? <i className="fas fa-spinner fa-spin"></i> : 'Entrar'}
                </button>
              </form>
              <small className="pt-20 text-center"><Link to="/autenticacao/recuperarSenha"><span data-bs-dismiss="modal">Esqueceu a senha?</span></Link></small>
            </div>
            <div className="col-md-1">
              <div className="d-none d-md-block">
                <div className="vl-wrap">
                  <div className="vl" style={{ height: '150px' }}></div>
                </div>
              </div>
              <div className="d-md-none pt-30 pb-25">
                <hr />
              </div>
              {/* <div className="row justify-content-center">
                <div className="col">
                </div>
              </div> */}
            </div>
            <div className="col-md-5">
              <p className="text-center"><b>Ainda não possuo cadastro</b></p>
              <button className="btn btn-secondary btn-sm btn-block" data-bs-target="#modalCadastroUsuario" data-bs-toggle="modal" data-bs-dismiss="modal">Cadastrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

}
import { useEffect } from 'react'
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.js';
import { NotificationManager } from 'react-notifications';
import api from './../../services/api'
import { ScrollToElement } from './../../services/utils'

export default function ModalSouRemetenteOuDestinatario(props) {
  const { cepRemetente, cepDestinatario, cepUsuario, callback } = props

  const modalSouRemetenteOuDestinatario = document.getElementById('modalSouRemetenteOuDestinatario')
  const origemOuDestino = document.getElementById('origemOuDestino')

  useEffect(function () {
    if (modalSouRemetenteOuDestinatario && (cepUsuario === cepRemetente || cepUsuario === cepDestinatario)) {
      let modal = new Modal(modalSouRemetenteOuDestinatario)
      modal.toggle()

      cepUsuario === cepRemetente ? origemOuDestino.innerHTML = "local de coleta" : origemOuDestino.innerHTML = "local de entrega"
    }
  }, [cepRemetente, cepDestinatario, cepUsuario, modalSouRemetenteOuDestinatario, origemOuDestino])

  const preencher = () => {
    // let modal = new Modal(modalSouRemetenteOuDestinatario)

    console.log(modalSouRemetenteOuDestinatario)
    // modal.toggle()

    api.get(`user/GetMeusDados`).then(response => {
      // console.log(response, "Endereço aqui")
      callback(response.data, cepUsuario === cepRemetente)
      ScrollToElement('#informacoes-carga')
    }).catch(err => {
      console.log("ERRO: ", err)
      NotificationManager.error('Ops, ocorreu um erro ao buscar os dados do endereço');
    })
  }

  return <div className="modal fade show" id="modalSouRemetenteOuDestinatario" name="modalSouRemetenteOuDestinatario">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body pt-30 pr-30 pb-30 pl-30">
          <div className="mb-60">
            <h4 className="text-center">Utilizar meus dados de cadastro</h4>
            <p className="text-center">Utilizar meus dados de cadastro para preencher as informações do <b className="text-vermelhopontual" id="origemOuDestino">DESTINATÁRIO</b> na tela de finalização?</p>
          </div>
          <div className="text-center" >
            <button className="btn btn-light btn-sm btn-wide" data-bs-dismiss="modal" onClick={() => ScrollToElement('#informacoes-carga')}>Não</button> &nbsp;
            <button className="btn btn-primary btn-sm btn-wide" data-bs-dismiss="modal" onClick={preencher}>Preencher</button>
          </div>
        </div>
      </div>
    </div>
  </div>
}
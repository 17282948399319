import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

export default function InputCheckbox({ name, ...rest }) {
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      setValue(ref, value) {
        ref.checked = value
      },
      clearValue(ref) {
        // ref.setInputValue('');
        ref.value = ''
      }
    })
  }, [fieldName, registerField])

  return <span>
    {/* {
      (rest.errors != null) && <span className='text-danger'>{rest.errors} <br/></span>
    } */}
    <input type="checkbox" name={fieldName} ref={inputRef} defaultValue={defaultValue} {...rest} />
  </span>
}
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Form } from '@unform/web'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import Input from './../form/input'
import CpfCnpjInput from './../form/cpfCnpjInput'
import SelectInput from './../form/selectInput'
import InputCheckbox from './../form/inputCheckbox'
import api from './../../services/api'
import storage from './../../services/storage'
import { TransportarCotacao } from './../../services/utils'
import { ChaveRecaptcha } from '../../services/utils'
import ReCAPTCHA from "react-google-recaptcha"


// import { Authenticate } from '../../services/auth'
// import { Context } from '../../services/contextApp'

export default function ModalCadastroUsuario() {
  const [bolLoading, setBolLoading] = useState(false)
  const [bolGeneralCep, setBolGeneralCep] = useState(false)
  const [mensagemErro, setMensagemErro] = useState("")
  const formRef = useRef(null)
  const recaptchaRef = useRef()

  const handleCep = async (cep) => {
    let _bolGeneralCep = false;

    if (cep.length === 8) {
      let address = await axios(`https://viacep.com.br/ws/${cep}/json/`).then((r) => r.data)

      if (!address.logradouro || !address.localidade) {
        _bolGeneralCep = true
      }

      formRef.current.setFieldValue('cidadeUF', `${address.localidade}/${address.uf}`)
      formRef.current.setFieldValue('Municipio', address.localidade)
      formRef.current.setFieldValue('UF', address.uf)
      formRef.current.setFieldValue('Logradouro', address.logradouro)
      formRef.current.setFieldValue('Bairro', address.bairro)
      formRef.current.setFieldValue('Complemento', address.complemento)
      formRef.current.setFieldValue('Logradouro', address.logradouro)

      setBolGeneralCep(_bolGeneralCep)
      console.log(address)
    }
  }

  const handleSubmit = (data) => {
    setBolLoading(true)
    data.Cpfcnpj = data.Cpfcnpj.replace(/\D/g, '')
    data.TipoPessoa = data.Cpfcnpj.length === 14 ? 2 : 1

    api.post('user/register', data).then(r => {
      // console.log('SUCESSO: ', r)
      setBolLoading(false)
      storage.setToken(r.data)
      document.getElementById('modalCadastroUsuario').click()
      if (localStorage.getItem('parametrosParaConsulta') != null) {
        TransportarCotacao(JSON.parse(localStorage.getItem('parametrosParaConsulta')))
        localStorage.removeItem('parametrosParaConsulta')
      }
    }).catch(err => {
      setBolLoading(false)
      if (err.response && err.response.status === 400) {
        console.log('ERRO: ', err.response)
        console.log('ERRO: ', err.response.data.message)
        setMensagemErro(err.response.data.message)
      }
      else
        NotificationManager.error('Não foi possível concluir o cadastro');
    });
  }

  const onSubmitWithReCAPTCHA = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    data.RecaptchaResponse = token.toString();
    handleSubmit(data);
  }

  const verificarSeCPFouCNPJ = (e) => {
    if (e.target.value?.replace(/(\D)/g, '').length === 14) {
      //PessoaJuridica
      document.getElementById("dadosPJ").style.display = 'flex';
      document.getElementById("dadosPF").style.display = 'none';

    } else {
      //PessoaFísica
      document.getElementsByName("UFIE")[0].value = ''
      document.getElementsByName("InscricaoEstadual")[0].value = ''
      document.getElementsByName("BolIsento")[0].checked = false
      document.getElementById("dadosPJ").style.display = 'none';
      document.getElementById("dadosPF").style.display = 'flex';
    }
  }

  const preencherNomeAPartirDoFantasia = (e) => {
    document.getElementsByName("Nome")[0].value = e.target.value
  }

  return <div className="modal fade" id="modalCadastroUsuario">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-body pt-30 pr-30 pb-30 pl-30">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="mb-20">
            <h3 className="text-center">Vamos criar seu cadastro</h3>
            {/* <p className="text-center">Lorem Ipsum, giving information on its origins</p> */}
            <div className="text-danger mb-20 text-center">
              <small>&nbsp;{mensagemErro}&nbsp;</small>
              <small className="pt-20 text-center">
                <Link to="/autenticacao/recuperarSenha">
                  <span data-bs-dismiss="modal">Recuperar acesso?</span>
                </Link>
              </small>
            </div>
          </div>
          <Form ref={formRef} onSubmit={onSubmitWithReCAPTCHA}>
            <div className="row">
              <div className="col-md-5">
                <CpfCnpjInput name="Cpfcnpj" type="text" title="CPF/CNPJ" onKeyUp={e => verificarSeCPFouCNPJ(e)} />
              </div>
            </div>
            <div className="row" id="dadosPF">
              <div className="col-md-12">
                <Input name="Nome" type="text" title="Nome Completo" />
              </div>
            </div>
            <div className="row" id="dadosPJ" style={{ display: 'none' }}>
              <div className="col-md-6">
                <Input name="RazaoSocial" type="text" title="Razão Social" />
              </div>
              <div className="col-md-6">
                <Input name="NomeFantasia" type="text" onKeyUp={e => preencherNomeAPartirDoFantasia(e)} title="Nome Fantasia" />
              </div>
              <div className="col-md-4">
                <SelectInput name="UFIE" className="input" Placeholder="Estado da IE" option={[{ value: 'df', descricao: 'DF' }, { value: 'rj', descricao: 'RJ' }, { value: 'sp', descricao: 'SP' }]} />
              </div>
              <div className="col-md-4">
                <Input name="InscricaoEstadual" type="text" title="Inscrição Estadual" />
              </div>
              <div className="col-md-4">
                <div className="form-check pt-15">
                  <InputCheckbox name="BolIsento" className="form-check-input" type="checkbox" id="flexCheckDefault" />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    ISENTO
                  </label>
                </div>
              </div>
            </div>
            <hr className="mt-0" />
            <div className="row">
              <div className="col-md-3">
                <Input name="NumeroCep" type="text" title="CEP" onChange={(event) => handleCep(event.target.value)} maxLength="8" />
              </div>
              <div className="col-md-4">
                <Input name="cidadeUF" type="text" title="Cidade/UF" disabled />
              </div>
              <div className="col-md-4 d-none">
                <Input name="Municipio" type="text" title="Município" hidden />
              </div>
              <div className="col-md-4 d-none">
                <Input name="UF" type="text" title="UF" hidden />
              </div>
              <div className="col-md-5">
                <Input name="Logradouro" type="text" title="Logradouro" placeholder="Logradouro" disabled={!bolGeneralCep} />
              </div>
              <div className="col-md-2">
                <Input name="Numero" type="text" title="Número" />
              </div>
              <div className="col-md-5">
                <Input name="Bairro" type="text" title="Bairro" disabled={!bolGeneralCep} />
              </div>
              <div className="col-md-5">
                <Input name="Complemento" type="text" title="Complemento" />
              </div>
            </div>
            <hr className="mt-0" />
            <div className="row">
              <div className="col-md-6">
                <Input name="Telefone" type="text" title="Telefone" required />
              </div>
              <div className="col-md-6">

              </div>
              <div className="col-md-6">
                <Input name="Email" type="email" title="E-mail" required />
              </div>
              <div className="col-md-6">
                <Input name="ConfirmaEmail" type="email" title="Confirme o E-mail" required />
              </div>
              <div className="col-md-6">
                <Input name="Senha" type="password" title="Senha" required />
              </div>
              <div className="col-md-6">
                <Input name="ConfirmaSenha" type="password" title="Confirme a Senha" required />
              </div>
            </div>
            <div>
              <button type="submit" className="btn btn-primary btn-block">
                {bolLoading ? <i className="fas fa-spinner fa-spin"></i> : 'Me Cadastrar'}
              </button>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={ChaveRecaptcha}
            // onChange={Enviar}
            />
          </Form>
          {/* <div className="row align-items-center justify-content-around">
            <div className="col-md-5 d-grid">
              <form>
                <input type="text" id="user" onChange={(event) => setUser(event.target.value)} className="input input-sm" placeholder="Usuário" />
                <input type="password" autoComplete="on" onChange={(event) => setPass(event.target.value)} className="input input-sm" placeholder="Senha" />
                <button type="submit" className="btn btn-primary btn-block btn-sm" onClick={(event) => login(user, pass, event)}>
                  {bolLogging ? <i className="fas fa-spinner fa-spin"></i> : 'Entrar'}
                </button>
              </form>
            </div>
            <div className="col-md-5">
              <p className="text-center"><b>Ainda não possuo cadastro</b></p>
              <button className="btn btn-secondary btn-sm btn-block">Cadastrar</button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>

}
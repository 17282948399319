import api from './api'

export const GetEnredecoPorCep = async cep => {
  cep = cep.replace(/\D/g, '')

  if (cep.length >= 8) {
    return await api.get(`endereco/${cep}`)
    // .then(response => {
    //   console.log(response.data)
    //   return response.data
    // }).catch(err => {
    //   console.log("ERRO: ", err)
    // })
  }
}

export const TransportarCotacao = parametrosParaConsulta => {
  // let history = useHistory();

  api.post("Cotacao/TransportarCotacao", parametrosParaConsulta).then(response => {
    console.log(response.data.guid)
    // history.push(`/checkout/${response.data.guid}`);
    window.location.href = `/checkout/${response.data.guid}`

    // exportQuotationItens(response.data)
    // setCounting(false)
  }).catch(err => {
    console.log("ERRO: ", err, parametrosParaConsulta)
    // setCounting(false)
  })
}

export const FormatarValor = (props) => {
  const { valor, tipo } = props
  let valorFormatado = valor;

  if (tipo === "BRL")
    valorFormatado = numberToFloat(valor)

  return valorFormatado
}

export const formataDateTime = (dateTime) => {

  let time = "HORA", date = "DATA"
  date = ("0" + dateTime.getDate()).slice(-2) + "/" + ("0" + dateTime.getMonth()).slice(-2) + "/" + (dateTime.getFullYear())
  time = ("0" + dateTime.getHours()).slice(-2) + ":" + ("0" + dateTime.getMinutes()).slice(-2)

  return { time, date }
}

export const DesativarBotaoCarregando = (idElm, Texto) => {
  let bolDisabled = document.getElementById(idElm).disabled

  document.getElementById(idElm).disabled = !bolDisabled
  document.getElementById(idElm).innerHTML = !bolDisabled ? "Carregando..." : Texto
}

function numberToFloat(numero) {
  numero = Number(numero).toFixed(2).split('.');
  numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
  return numero.join(',');
}

export function ScrollToElement(querySelector) {
  const element = document.querySelector(querySelector)
  const topPos = element.getBoundingClientRect().top + window.pageYOffset

  window.scrollTo({
    top: topPos, // scroll so that the element is at the top of the view
    behavior: 'smooth' // smooth scroll
  })
}

export const ChaveRecaptcha = "6LeIYXwhAAAAAA6x1fvveRzEcRogAfUBBUtWHkpv"

export default <></>
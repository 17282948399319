import { Modal as Modalrb } from 'react-bootstrap'

export default function Modal(props) {
  return (
    <>
      <Modalrb show={props.show} onHide={props.close}>
        <Modalrb.Body className='pt-30 pr-30 pb-30 pl-30'>
          <button type="button" className="btn-close" onClick={props.close}></button>
          {props.children}
        </Modalrb.Body>
      </Modalrb>
    </>
  );
}
//https://react-bootstrap.github.io/components/modal
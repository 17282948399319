import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
// import ReactInputMask from 'react-input-mask';
import { GetEnredecoPorCep } from '../../services/utils'

export default function CepInput({ title, name, ...rest }) {
  const inputRef = useRef(null)
  const labelRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      }
    })
  }, [fieldName, registerField])

  const handleText = (e) => {
    let cep = inputRef.current.value.replace(/\D/g, '').substr(0, 8);
    let elmLabel = document.getElementById(`label-${fieldName}`);
    elmLabel.textContent = '';
    elmLabel.value = '';
    // e.selectionEnd = 8

    if (cep.length < 8)
      return inputRef.current.value = cep

    labelRef.current.style.display = "inline"

    GetEnredecoPorCep(cep).then(r => {
      let endereco = r.data//console. log(r)     
      labelRef.current.style.display = "none"

      //console.log(endereco)
      inputRef.current.value = cep.replace(/([0-9]{2})([0-9]{3})([0-9]{3})/, "$1.$2-$3")

      if (endereco) {
        elmLabel.textContent = ' (' + endereco.municipio + '/' + endereco.uf + ')'
        elmLabel.value = endereco.municipio + '/' + endereco.uf
        //inputRef.current.value += ' (' + endereco.municipio + '/' + endereco.uf + ')'
      }
      else
        elmLabel.textContent = ' (Não encontrado)'
      //inputRef.current.value += ' (Não encontrado)'
    }).catch(err => {
      labelRef.current.style.display = "none"
      elmLabel.textContent = '(Ops. Não foi possível localizar)'
    })
  }
  //https://velhobit.com.br/design/efeito-float-label-animado-com-css3-igual-ao-do-novo-login-do-google.html
  return <>
    <div className="form-floating mb-3">
      <input id={fieldName} ref={inputRef} defaultValue={defaultValue} {...rest} onKeyUp={(e) => handleText(e)} placeholder={title} type="text" className="form-control" />
      <label htmlFor={fieldName}>{title} <span id={`label-${fieldName}`}></span></label>
      <span ref={labelRef} className='fa fa-spinner fa-spin' style={{ position: "absolute", top: "calc(50% - 6px)", right: "10px", display: "none" }}></span>
    </div>
  </>
}
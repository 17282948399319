import api from './api'
import storage from './storage'


export async function Authenticate(user, password) {
  try {
    const response = await api.post('login', { Username: user, Password: password }).then((r) => r)
    // console.log(response)
    storage.setToken(response.data.token)
    // setAuthenticated(true)
    // setUser(user)
    return response

  } catch (err) {

    return err.response
    // if (err.response.status === 401) {
    //   notification.warn(err.response.data.message);
    // } else {
    //   notification.warn('Ocorreu um erro inesperado ao tentar realizar autenticação.');
    // }
    // console.log("Deu treta", err)
  }
}

export function Signout() {
  storage.clearToken()
}

export default function IsAuthenticated() {
  return storage.isAuthenticated()
}
import { useEffect, useRef } from 'react'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'

export const PopoverPrazos = (props) => {
  const popoverRef = useRef()
  useEffect(() => {
    new bootstrap.Popover(popoverRef.current, {
      content: "Após confirmação de pagamento! <br/> Cartão: <b>imediato </b> <br/> PIX: <b>imediato </b> <br/> Boleto: <b>até 3 dias </b>",
      trigger: "hover",
      html: true
      // title: "Como funciona?",
    })
  })
  return (<i className="fas fa-exclamation-circle cursor text-azulpontual" ref={popoverRef}></i>)
}

export default <></>
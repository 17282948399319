import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Context } from './../../services/contextApp'
import { Signout } from './../../services/auth'
import Logo from './../../assets/media/logo-atualizada.png'
import Hamburger from './../../assets/media/menu-hamburger.svg'

export default function Nav() {
  const { userContext, authenticatedContext } = useContext(Context)
  return <div>
    {/* <nav className="navbar navbar-expand-lg mt-25"> */}
    <nav className="navbar navbar-white navbar-expand-lg navbar-fixed-top">
      <div className="container">
        {
          authenticatedContext ?
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
              <img src={Hamburger} alt="Hamburguer Menu" />
            </button>
            :
            <button className="navbar-toggler btn btn-outline-danger btn-sm ml-10" type="button" data-bs-toggle="modal" data-bs-target="#modalLogin">Entrar</button>
        }
        {/* <a className="navbar-brand" href="/"> */}
        <Link to="/" className="navbar-brand">
          <img style={{height: '80px'}} src={Logo} alt="Logo Pontual Cargo" />
        </Link>
        {/* </a> */}
        <div className="collapse navbar-collapse order-3" id="navbarTogglerDemo03">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
          </ul>
          <form className="d-flex">
            {
              !authenticatedContext ?
                <button className="btn btn-outline-danger btn-sm ml-10" type="button" data-bs-toggle="modal" data-bs-target="#modalLogin">Entrar</button>
                :
                <div className="btn-group ml-10" role="group">
                  <button id="btnGroupDrop1" type="button" className="btn btn-outline-danger btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    Olá, {userContext}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <li><Link className="dropdown-item" to="/meuspedidos">Meus Pedidos</Link></li>
                    <li><a className="dropdown-item" href="/" onClick={Signout}>Sair</a></li>
                  </ul>
                </div>
            }
          </form>
        </div>
      </div>
    </nav>
    <svg viewBox="0 0 100 100" preserveAspectRatio="none">
      <polygon points="0,0 0,100 100,0" height="18"></polygon>
    </svg>
  </div>
}
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import api from './../../services/api'
import Modal from './../modal'
import Input from '../form/input'

export default function ModalTrechoNaoAtendido(props) {
  let { show, close, dados } = props
  const formRef = useRef(null)

  const SalvarCotacaoTrechoNaoAtendido = (data) => {
    //console.log('Dados Cotacao: ', data, dados.objRetorno)
    let x = {
      ...dados.objRetorno,
      ...data
    }

    console.log(x)
    api.post('cotacao/SalvarCotacaoTrechoNaoAtendido', x)
    close(false)
  }

  return (
    <Modal show={show} close={() => close(false)} >
      <div className="mb-20">
        <h3 className="text-center">Ops</h3>
        <p className="text-center">
          {dados.mensagem}
          {/* Esta localidade (São Félix do Coribe/BA) ainda não é atendida pelo serviço <b>éFácil</b>,<br />
          deixe seu contato para continuar com o transporte. */}
        </p>
      </div>
      <Form ref={formRef} onSubmit={SalvarCotacaoTrechoNaoAtendido}>
        <div className='row justify-content-md-center'>
          <div className="col-auto">
            <Input name="telefone" title="Contato" type="text" required maxLength="11" />
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">Enviar</button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
//https://react-bootstrap.github.io/components/modal